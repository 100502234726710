<template>
  <li class="head">
    <div :class="{bold: isFolder}" @click="toggle" @dblclick="changeType">
      <div class="media">
        <img
          class="d-flex mr-3"
          src="@/assets/img/logo.png"
          width="60"
          alt="Generic placeholder image"
        >
        <div class="media-body">
          <span>
            <strong class="col-md-2">Unit Kerja SS:</strong>
            {{ model.position }}
          </span>
          <br>
          <span>
            <strong class="col-md-2">Jabatan:</strong>
            {{ model.jabatan }}
          </span>
          <br>
          <span>
            <strong class="col-md-2">Status:</strong>
            {{model.status}}
          </span>
          <br>
          <span>
            <strong class="col-md-2">Profil:</strong>
            {{ model.profil }}
          </span>
          <br>
          <span>
            <strong class="col-md-2">Catatan:</strong>
            <span v-html="model.description"></span>
          </span>
        </div>
        <span v-if="isFolder" class="pull-right">
          <i :class="!open ?'mdi mdi-chevron-right':'mdi mdi-chevron-down'"></i>
        </span>
      </div>
    </div>
    <ul class="tree-view" v-show="open" v-if="isFolder">
      <item v-for="(model, index) in model.children" :key="index" :model="model"></item>
    </ul>
  </li>
</template>
<script>
import Vue from "vue";
import item from "./item";
export default {
  components: {
    item
  },
  props: {
    model: Object
  },
  data() {
    return {
      open: true
    };
  },
  computed: {
    isFolder() {
      return this.model.children && this.model.children.length;
    }
  },
  methods: {
    toggle() {
      if (this.isFolder) {
        this.open = !this.open;
      }
    },
    changeType() {
      if (!this.isFolder) {
        Vue.set(this.model, "children", []);
        this.addChild();
        this.open = true;
      }
    }
  }
};
</script>
<style >
.head {
  list-style-type: none;
}
.item {
  cursor: pointer;
}
.tree-view {
  list-style-type: none;
  padding-left: 3em;
  line-height: 1.5em;
}
@media only screen and (max-width: 600px) {
  .tree-view {
    list-style-type: none;
    padding-left: 0.5em;
    line-height: 1.5em;
  }
}
</style>
